import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"

import Layout from "../../../components/common/layout/layout"

import health from "../../../images/icons/Icon/Health.png"
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'


const query = graphql`
  query {
    allStrapiStore {
      edges {
        node {
          storeName
          storeLink
          storeDescription
          storeCategory
          storeImage {
            childImageSharp {
              fixed(width: 320, height: 240) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;


const DGrooming = () => (
  <Layout>
    <CategoryContainer>
        <Icon src={health}></Icon>
        <CategoryHeader>Grooming and Cosmetic Treatments</CategoryHeader>
        <CategoryText>Need a nose job, a chin tuck or a hair transplant. Cannot do it right away as it is expensive? Now don’t miss out on life’s best moments, take a dili loan and sculpt your body to match your dream profile.
        <br />
        <br />
        Want to look your best for that once in a lifetime event, go ahead and avail of beauty and grooming services at the best salons in town. Let dili help you with the expense because looking good is not just about getting the make-up or hair style right but also flaunting that the best celebrity stylist in town did it for you.
        <br/>
        <br/>
        Use dili loans to pay for : 
        <ul>
            <li>Hair transplants, styling and other treatments</li>
            <li>Bridal and special occasion make-up and cosmetic treatments</li>
            <li>Skin and other cosmetic treatments</li>
            <li>Dental and Eye related cosmetic interventions e.g. Braces, Lasix interventions</li>
        </ul>
        </CategoryText>
    
    <Spacing />
    <Stores>
        <StaticQuery
            query={query}
            render={data => (
                <StoreC>
                    {data.allStrapiStore.edges.filter(store => store.node.storeCategory === "grooming").map(store => (
                        <Store>
                            <StoreImg fixed={store.node.storeImage.childImageSharp.fixed}/>
                            <StoreHeader>{store.node.storeName}</StoreHeader>
                            <StoreText>{store.node.storeDescription}</StoreText>
                            <Link to={store.node.storeLink}>
                                <StoreButton>Shop now</StoreButton>
                            </Link>
                        </Store>
                    ))}
                </StoreC>
            )}
        />
    </Stores>
    <Spacing />
    <FormText>
    <FormHeader>
        Don’t see what you want to buy here? Help us curate a loan for you.
    </FormHeader>
    <FormSubheader>
        At dili, we are constantly looking to serve our customers better. If your requirement does not fit in the categories listed above, or the merchant you wish to buy from is not currently signed up with dili, tell us about it and we will try to curate a loan for you. 
    </FormSubheader>
    </FormText>
    <FormContainer>
        <FormEmbed src="https://docs.google.com/forms/d/e/1FAIpQLScbyaRY2G20xafoCvqNfw_37ZlsT9-bR63qV-LoW__eiKKkig/viewform?embedded=true" width="640" height="640" frameBorder="0">
            Loading...
        </FormEmbed>
    </FormContainer>
    </CategoryContainer>
  </Layout>
)

export default DGrooming


export const Spacing = styled.div`
    margin-bottom: 120px;
`

export const CategoryContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
`

export const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
`

export const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 20px;
    width: 70%;
`

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const Stores = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    margin-bottom: 80px;
`

export const StoreImg = styled(Img)`
    margin-bottom: 16px;
    border-radius: 20px;
`

export const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    width: 320px;
`

export const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
    width: 320px;
    line-height: 1.3;
`

export const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`

const FormText = styled.div`
    flex-direction: column;
    margin-top: 120px;
`

const FormHeader = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: #142630;
    width: 60%;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 40px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        width: 80%;
    }
`

const FormSubheader = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60%;
    font-size: 20px;
    color: #465F6D;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 480px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 360px;
        font-size: 14px;
    }
`

const FormContainer = styled.div`
    display: flex;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
    width: 640px;
    height: 640px;
`

export const StoreC = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`